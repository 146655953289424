import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";

// Create the context
const StatusContext = createContext();

const StatusProvider = ({ children }) => {
  const [status, setStatus] = useState({});
  const wsInitialized = useRef(false);

  const token = localStorage.getItem("token");

  const handleSocket = async () => {
    const socket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}ws/staff-statuses?token=${token}`
    );

    socket.addEventListener("open", (event) => {
      console.log("WebSocket connection opened:", event);
    });

    socket.addEventListener("message", (event) => {
      const staffData = JSON.parse(event.data);
      setStatus(staffData); // Update the status state with received data
    });

    socket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
    });

    socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed:", event);
    });
  };

  useEffect(() => {
    if (!wsInitialized.current) {
      wsInitialized.current = true;
      handleSocket();
    }
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      wsInitialized.current = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    // Provide the status value through the context
    <StatusContext.Provider value={status}>{children}</StatusContext.Provider>
  );
};

// Custom hook to consume the status context
const useStatusContext = () => {
  const status = useContext(StatusContext);
  return status;
};

export { StatusProvider, useStatusContext };
