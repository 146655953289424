import React from "react";
import { Spinner } from "react-bootstrap";

function Buffer() {
  return (
    <div className="loading">
      <Spinner animation="border" role="status" variant="light" />
    </div>
  );
}

export default Buffer;
