import React from "react";

// export class ErrorBoundary extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             error: null,
//         };
//     }
//     componentDidCatch(error, info) {
//         console.log(error, info);
//     }
//     static getDerivedStateFromError(error) {
//         return { error };
//     }
//     render() {
//         if (this.state.error) return <div>Something went wrong.</div>;
//         return this.props.children;
//     }
// }

// export default ErrorBoundary;

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        // logErrorToMyService(error, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}
