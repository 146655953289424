import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated"),
  token: localStorage.getItem("token"),
};

export const authSlice = createSlice({
  name: "loggedIn",
  initialState,
  reducers: {
    logIn: (state) => {
      state.isAuthenticated = true;
    },
    logOut: (state) => {
      state.isAuthenticated = false;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
});

export const { logIn, logOut, setToken } = authSlice.actions;

export default authSlice.reducer;
