import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "../components/navbar/drawerSlice";
import authSlice from "../auth/authSlice";
import userSlice from "../pages/UAM/userSlice";

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    authSlice,
    userSlice,
  },
});

export default store.dispatch;
