import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "suneditor/dist/css/suneditor.min.css";
import "react-phone-input-2/lib/style.css";
import { Fragment, lazy, Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import Buffer from "./components/buffer/Buffer";
import { Row, Container, Col } from "react-bootstrap";
import { images } from "./assets/images";
const WebSetting = lazy(() => import("./pages/website-settings/WebSetting"));
const WebSettingsOutlet = lazy(() => import("./components/web-settings/WebSettingsOutlet"));
const TeamMemberTarget = lazy(() => import("./pages/revenue-matrix/TeamMemberTarget"));
const ApproveMemberTarget = lazy(() =>
    import("./components/revenue-matrix/member-target/ApproveMemberTarget")
);
const SelfTarget = lazy(() => import("./pages/revenue-matrix/SelfTarget"));
const RevenueTargetOutlet = lazy(() =>
    import("./components/revenue-matrix/RevenueMatrixOutlet.jsx")
);
const EditApprovalLeads = lazy(() =>
    import("./components/leadComponents/approve-leads/EditApprovalLeads")
);
const OnboardingBrandEdit = lazy(() => import("./pages/brand-onboarding-data/OnboardingBrandEdit"));
const NewBrands = lazy(() => import("./pages/brand-onboarding-data/NewBrands"));
const BrandOnboarding = lazy(() => import("./pages/brand-onboarding/BrandOnboarding"));
const ManageOnboardingOutlet = lazy(() =>
    import("./pages/brand-onboarding-data/ManageOnboardingOutlet")
);
const DirectoryOutlet = lazy(() => import("./components/directories/DirectoryOutlet"));
const CreateRole = lazy(() => import("./pages/UAM/Roles/CreateRole"));
const BrandsDirectory = lazy(() => import("./components/directories/BrandsDirectory"));
const ViewBrand = lazy(() => import("./components/view-brand/ViewBrand"));
const UserDirectory = lazy(() => import("./components/directories/UserDirectory"));
const Logs = lazy(() => import("./pages/logs/Logs"));
const Report = lazy(() => import("./pages/report/Report"));
const AllBugs = lazy(() => import("./pages/report/AllBugs"));
const BugOutlet = lazy(() => import("./pages/report/BugOutlet"));
const SingleBug = lazy(() => import("./pages/report/SingleBug"));
const UpdateBrand = lazy(() => import("./pages/brand/UpdateBrand"));
const RegionOutlet = lazy(() => import("./pages/region/RegionOutlet"));
const Region = lazy(() => import("./pages/region/Region"));
const BrandOutlet = lazy(() => import("./pages/brand/BrandOutlet"));
const Brands = lazy(() => import("./pages/brand/Brand"));
const CreateBrand = lazy(() => import("./pages/brand/CreateBrand"));
const Category = lazy(() => import("./pages/brand/category/Category"));
const FillOtp = lazy(() => import("./auth/FillOtp"));
const NotFound = lazy(() => import("./pages/errorPages/NotFound"));
const FirstTimeUser = lazy(() => import("./auth/FirstTimeUser"));
const UpdateUserData = lazy(() => import("./pages/UAM/UpdateUserData"));
const CreateUser = lazy(() => import("./pages/UAM/CreateUser"));
const UAMOutlet = lazy(() => import("./pages/UAM/UAMOutlet"));
const UAM = lazy(() => import("./pages/UAM/UAM"));
const Roles = lazy(() => import("./pages/UAM/Roles/Roles"));
const RootLayout = lazy(() => import("./RootLayout"));
const Home = lazy(() => import("./pages/home/Home"));
const MarketingDashboard = lazy(() => import("./pages/home/MarketingDashboard"));
const Login = lazy(() => import("./auth/Login"));
const ResetPassword = lazy(() => import("./auth/ResetPassword"));
const PasswordChange = lazy(() => import("./auth/PasswordChange"));
const LeadOutlet = lazy(() => import("./pages/Lead/LeadOutlet"));
const BdLeadOutlet = lazy(() => import("./pages/bd-leads/BdLeadOutlet"));
const Lead = lazy(() => import("./pages/Lead/Lead"));
const BdLead = lazy(() => import("./pages/bd-leads/BdLead"));
const SingleLead = lazy(() => import("./pages/Lead/SingleLead"));
const SingleWonLead = lazy(() => import("./pages/Lead/SingleWonLead"));
const SingleWonBdLead = lazy(() => import("./pages/bd-leads/SingleWonBdLead"));
const SingleBdLead = lazy(() => import("./pages/bd-leads/SingleBdLead"));
const BdLeadWonForm = lazy(() => import("./pages/bd-leads/BdLeadWonForm"));
const AssignLead = lazy(() => import("./pages/Lead/AssignLead"));
const TransferLead = lazy(() => import("./pages/Lead/TransferLead"));
const TransferBdLead = lazy(() => import("./pages/bd-leads/TransferBdLead"));
const WonLead = lazy(() => import("./pages/Lead/WonLead"));
const WonBdLead = lazy(() => import("./pages/bd-leads/WonBdLead"));
const AppLayout = lazy(() => import("./AppLayout"));
const Profile = lazy(() => import("./auth/Profile"));
const RequestLeads = lazy(() => import("./pages/Lead/LeadRequest"));
const ApproveLeads = lazy(() => import("./pages/Lead/ApproveLeads"));
const ApproveLeadsOutlet = lazy(() =>
    import("./components/leadComponents/approve-leads/ApproveLeadsOutlet")
);
const ChatApp = lazy(() => import("./pages/chatApp/ChatApp"));
const StatsOutlet = lazy(() => import("./components/user-analytics/StatsOutlet"));
const UserStats = lazy(() => import("./pages/user-stats/UserStats"));
const RegionStats = lazy(() => import("./pages/user-stats/RegionStats"));
const SingleUserStats = lazy(() => import("./components/user-analytics/user-wise/SingleUserStats"));
const SingleRegionStats = lazy(() => import("./components/user-analytics/region-wise/SingleRegionStats"));

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            Component: AuthWrapper(AppLayout),
            children: [
                {
                    index: true,
                    Component: AuthWrapper(Home),
                },
                {
                    path: "marketing-dashboard",
                    Component: AuthWrapper(MarketingDashboard),
                },
                {
                    path: "profile",
                    Component: AuthWrapper(Profile),
                },
                {
                    path: "change-password",
                    Component: AuthWrapper(PasswordChange),
                },
                {
                    path: "directory",
                    Component: AuthWrapper(DirectoryOutlet),
                    children: [
                        {
                            path: "all-users",
                            Component: AuthWrapper(UserDirectory),
                        },
                        {
                            path: "all-brands",
                            Component: AuthWrapper(DirectoryOutlet),
                            children: [
                                {
                                    index: true,
                                    Component: AuthWrapper(BrandsDirectory),
                                },
                                {
                                    path: "view/:brandId",
                                    Component: AuthWrapper(ViewBrand),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "uam",
                    Component: AuthWrapper(UAMOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(UAM),
                        },
                        {
                            path: "new",
                            Component: AuthWrapper(CreateUser),
                        },
                        {
                            path: "edit/:userId",
                            Component: AuthWrapper(UpdateUserData),
                        },
                        {
                            path: "roles",
                            Component: AuthWrapper(Roles),
                        },
                        {
                            path: "roles/new",
                            Component: AuthWrapper(CreateRole),
                        },
                    ],
                },
                {
                    path: "lead",
                    Component: AuthWrapper(LeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(Lead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleLead),
                        },
                    ],
                },
                {
                    path: "assign-leads",
                    Component: AuthWrapper(LeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(AssignLead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleLead),
                        },
                    ],
                },
                {
                    path: "transfer-leads",
                    Component: AuthWrapper(LeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(TransferLead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleLead),
                        },
                    ],
                },
                {
                    path: "won-leads",
                    Component: AuthWrapper(LeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(WonLead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleWonLead),
                        },
                    ],
                },
                {
                    path: "request-leads",
                    Component: AuthWrapper(RequestLeads),
                },
                {
                    path: "approve-leads",
                    Component: AuthWrapper(ApproveLeadsOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(ApproveLeads),
                        },
                        {
                            path: ":staff_id",
                            Component: AuthWrapper(EditApprovalLeads),
                        },
                    ],
                },
                {
                    path: "bd-lead",
                    Component: AuthWrapper(BdLeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(BdLead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleBdLead),
                        },
                        {
                            path: ":leadId/won",
                            Component: AuthWrapper(BdLeadWonForm),
                        },
                    ],
                },
                {
                    path: "transfer-bd-leads",
                    Component: AuthWrapper(BdLeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(TransferBdLead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleBdLead),
                        },
                    ],
                },
                {
                    path: "won-bd-leads",
                    Component: AuthWrapper(BdLeadOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(WonBdLead),
                        },
                        {
                            path: ":leadId",
                            Component: AuthWrapper(SingleWonBdLead),
                        },
                    ],
                },
                {
                    path: "region",
                    Component: AuthWrapper(RegionOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(Region),
                        },
                    ],
                },
                {
                    path: "brands",
                    Component: AuthWrapper(BrandOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(Brands),
                        },
                        {
                            path: "new",
                            Component: AuthWrapper(CreateBrand),
                        },
                        {
                            path: "edit/:brandId",
                            Component: AuthWrapper(UpdateBrand),
                        },
                        {
                            path: "category",
                            Component: AuthWrapper(Category),
                        },
                    ],
                },
                {
                    path: "manage-onboarding-brands",
                    Component: AuthWrapper(ManageOnboardingOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(NewBrands),
                        },
                        {
                            path: "edit/:brandId",
                            Component: AuthWrapper(OnboardingBrandEdit),
                        },
                    ],
                },
                {
                    path: "user-stats/user",
                    Component: AuthWrapper(StatsOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(UserStats),
                        },
                        {
                            path: "edit/:staffId",
                            Component: AuthWrapper(SingleUserStats),
                        },
                    ],
                },
                {
                    path: "user-stats/region",
                    Component: AuthWrapper(StatsOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(RegionStats),
                        },
                        {
                            path: "view/:regionId",
                            Component: AuthWrapper(SingleRegionStats),
                        },
                    ]
                },
                {
                    path: "revenue-matrix",
                    Component: AuthWrapper(RevenueTargetOutlet),
                    children: [
                        {
                            path: "self-target",
                            Component: AuthWrapper(SelfTarget),
                        },
                        {
                            path: "team-member-target",
                            Component: AuthWrapper(RevenueTargetOutlet),
                            children: [
                                {
                                    index: true,
                                    Component: AuthWrapper(TeamMemberTarget),
                                },
                                {
                                    path: ":staff_id",
                                    Component: AuthWrapper(ApproveMemberTarget),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "logs",
                    Component: AuthWrapper(Logs),
                },
                {
                    path: "website-settings",
                    Component: AuthWrapper(WebSettingsOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(WebSetting),
                        },
                    ],
                },
                {
                    path: "report",
                    Component: AuthWrapper(Report),
                },
                {
                    path: "all-bugs",
                    Component: AuthWrapper(BugOutlet),
                    children: [
                        {
                            index: true,
                            Component: AuthWrapper(AllBugs),
                        },
                        {
                            path: ":bugId",
                            Component: AuthWrapper(SingleBug),
                        },
                    ],
                },
                {
                    path: "chat-app",
                    Component: AuthWrapper(ChatApp),
                },
                {
                    path: "*",
                    Component: AuthWrapper(NotFound),
                },
            ],
        },
        {
            path: "login",
            Component: RootLayout,
            children: [
                {
                    index: true,
                    Component: Login,
                },
                {
                    path: "verify-user",
                    Component: AuthWrapper(FirstTimeUser),
                },
                {
                    path: "reset-password",
                    Component: ResetPassword,
                },
                {
                    path: "verify-account",
                    Component: AuthWrapper(FillOtp),
                },
            ],
        },
        {
            path: "brand-onboarding",
            Component: BrandOnboarding,
        },
    ]);

    const isMobile = window.innerWidth < 1024;

    return (
        <div className="app">
            <Fragment>
                {isMobile ? (
                    <div className="login-form">
                        <Container className="h-100">
                            <Row className="justify-content-center align-content-center h-100">
                                <Col md={8} lg={10}>
                                    <img src={images.mobile} alt="kya krega?" />
                                    <div className="form-card p-30 rounded-3 shadow-primary text-center">
                                        <h1 className="fs-2 mb-3">
                                            Sorry, this website is not available on mobile devices.
                                        </h1>
                                        <p className="fs-18">
                                            Please log in using a desktop device.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) : (
                    <Suspense fallback={<Buffer />}>
                        <RouterProvider router={router} />
                    </Suspense>
                )}
            </Fragment>
        </div>
    );
}

export default App;
