import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./index.css";
import { StatusProvider } from "./context/statusContext";
import { ErrorBoundary } from "./error-boundary/ErrorBoundary";

const ErrorUI = () => (
  <div className="d-flex justify-content-center align-items-center w-100 h-100">
    <h3>Something Went Wrong!</h3>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StatusProvider>
      <Provider store={store}>
        <ErrorBoundary fallback={<ErrorUI />}>
          <App />
        </ErrorBoundary>
      </Provider>
    </StatusProvider>
  </React.StrictMode>
);
