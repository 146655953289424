import jwtDecode from "jwt-decode";
import { Navigate } from "react-router-dom";

function isTokenExpire(isAuthenticated) {
  // const decodeToken = jwtDecode(isAuthenticated).iat + 3600;
  const decodeToken = jwtDecode(isAuthenticated).exp;
  const currentTime = Math.trunc(Date.now() / 1000);
  return currentTime > decodeToken;
}

function AuthWrapper(WrappedComponent) {
  // const navigate = useNavigate();

  const HOC = (props) => {
    let isAuthenticated = localStorage.getItem("token");

    if (isAuthenticated === null) {
      return <Navigate to="/login" replace={true} />;
    }
    if (isTokenExpire(isAuthenticated)) {
      // instance
      //   .post("/auth/logout/")
      //   .then((response) => {
      //     if (response.status >= 200 && response.status <= 299) {
      //       localStorage.clear();
      //       // navigate("/login");
      //       console.warn("Session Expired! Login Again!");
      //       return <Navigate to="/login" replace={true} />;
      //       // toast.warning("Session Expired! Login Again!", {
      //       //   position: "top-center",
      //       //   autoClose: 1000,
      //       // });
      //     } else {
      //       // toast.error(response.error.data.detail, {
      //       //   position: "top-right",
      //       //   autoClose: 1000,
      //       // });
      //       console.error(response.error.data.detail);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error.response.data.detail);
      //     // toast.error(error.response.data.detail, {
      //     //   position: "top-right",
      //     //   autoClose: 1000,
      //     // });
      //   });
      localStorage.clear();
      return <Navigate to="/login" replace={true} />;
    }
    return <WrappedComponent {...props} />;
  };

  return HOC;
}

export default AuthWrapper;
