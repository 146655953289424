import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    drawerShow: (state) => {
      state.value = true;
    },
    drawerHide: (state) => {
      state.value = false;
    },
  },
});

export const { drawerShow, drawerHide } = drawerSlice.actions;

export default drawerSlice.reducer;
