import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  userData: [],
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.userData = action.payload;
      state.loading = false;
    },
    addUser: (state, action) => {
      state.userData.push(action.payload);
    },
    editUser: (state, action) => {
      state.userData = state.userData.map((user) => {
        if (user.user_id === action.payload.user_id) return action.payload;
        else return user;
      });
    },
    deleteUser: (state, action) => {
      state.userData = state.userData.filter((user) => {
        return user.user_id !== action.payload.user_id;
      });
    },
  },
});

export const { setUsers, addUser, editUser, deleteUser } = userSlice.actions;
export default userSlice.reducer;
