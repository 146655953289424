import logo from "./logo.png";
import mobile from "./phone.png";
import leadSample from "./lead_bulk_upload_template.xlsx";
import fran_logo from "./franchisebrigade_logo.jpg";
import dummy_user from "./dummy-user.png";
import dummy_group from "./dummy-group.png";
import dummy_broadcast from "./dummy_broadcast.png";
import chat_icon from "./chat-icon.png";

export const images = {
  logo,
  mobile,
  leadSample,
  fran_logo,
  dummy_user,
  dummy_group,
  dummy_broadcast,
  chat_icon,
};
